import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Contenido from '../../../layout/Contenido';
import Grid from '@material-ui/core/Grid';
import SeccionCard from '../../SeccionCard';

import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';

import BeenhereTwoToneIcon from '@material-ui/icons/BeenhereTwoTone';
import { green } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import {
	countryToFlag,
	esPedidoEmailBloqueado,
	getPedidosCompletos,
	getPedidosCompletosEstadosUnidos,
	getPedidosCompletosInglaterra,
	getPedidosGuanxe,
	getPedidosMiravia,
	tieneProductoSinStock,
} from '../../../utils';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: '1rem',
	},
}));

export default function Dashboard() {
	const classes = useStyles();

	const { esAdmin } = useSelector((content) => content.User);
	const [loadingOrder, setLoadingOrder] = useState(false);

	const {
		fetching: fetchingPedidos,
		pedidos,
		ultimaActualizacion: ultimaActualizacionPedidos,
	} = useSelector((content) => content.Pedidos);
	const { paralizados } = useSelector((content) => content.Paralizados);

	const pedidosSinStock = pedidos.filter((p) => tieneProductoSinStock(p));
	const pedidosEmailBloqueado = paralizados.filter((p) =>
		esPedidoEmailBloqueado(p)
	);

	useEffect(() => {
		setLoadingOrder(fetchingPedidos && !ultimaActualizacionPedidos);
	}, [fetchingPedidos, ultimaActualizacionPedidos]);

	return (
		<Contenido title='Dashboard'>
			<div className={classes.root}>
				{esAdmin && (
					<>
						{pedidosSinStock.length > 0 && (
							<Alert
								variant='filled'
								severity={'error'}
								style={{ marginBottom: '0.5rem' }}>
								<AlertTitle>
									{pedidosSinStock.length} Pedidos sin stock
								</AlertTitle>
								Resuelve el conflicto ocurrido en estos pedidos para que puedan
								ser completados y preparados
							</Alert>
						)}
						{pedidosEmailBloqueado.length > 0 && (
							<Alert severity={'info'} style={{ marginBottom: '0.5rem' }}>
								Existen {pedidosEmailBloqueado.length} pedidos de prueba. Puedes
								ver estos pedidos en la seccion de pedidos bloqueados
								<br />
								<b>
									Elimina estos pedidos para aligerar los calculos del sistema.
								</b>
							</Alert>
						)}
					</>
				)}

				<Grid
					container
					direction='row'
					justifyContent={'center'}
					alignItems={'flex-start'}>
					{loadingOrder && (
						<>
							<Grid item>
								{' '}
								<Skeleton
									variant='rect'
									width={220}
									height={230}
									style={{
										margin: '1rem',
										borderRadius: '5px',
									}}
								/>{' '}
							</Grid>
							<Grid item>
								{' '}
								<Skeleton
									variant='rect'
									width={220}
									height={230}
									style={{
										margin: '1rem',
										borderRadius: '5px',
									}}
								/>{' '}
							</Grid>
							<Grid item>
								{' '}
								<Skeleton
									variant='rect'
									width={220}
									height={230}
									style={{
										margin: '1rem',
										borderRadius: '5px',
									}}
								/>{' '}
							</Grid>
							<Grid item>
								{' '}
								<Skeleton
									variant='rect'
									width={220}
									height={230}
									style={{
										margin: '1rem',
										borderRadius: '5px',
									}}
								/>{' '}
							</Grid>
							<Grid item>
								{' '}
								<Skeleton
									variant='rect'
									width={220}
									height={230}
									style={{
										margin: '1rem',
										borderRadius: '5px',
									}}
								/>{' '}
							</Grid>
							<Grid item>
								{' '}
								<Skeleton
									variant='rect'
									width={220}
									height={230}
									style={{
										margin: '1rem',
										borderRadius: '5px',
									}}
								/>{' '}
							</Grid>
						</>
					)}

					<Grid item hidden={loadingOrder}>
						<SeccionCard
							active={getPedidosCompletos(pedidos).length > 0}
							titulo='Preparación'
							icon={
								<BeenhereTwoToneIcon
									style={{
										fontSize: 80,
										color: green[500],
									}}
								/>
							}
							to='completos'
							loading={!ultimaActualizacionPedidos && fetchingPedidos}>
							<div style={{ textAlign: 'center' }}>
								{getPedidosCompletos(pedidos).length} pedidos
							</div>
						</SeccionCard>
					</Grid>

					<Grid item hidden={loadingOrder}>
						<SeccionCard
							active={getPedidosCompletosInglaterra(pedidos).length > 0}
							titulo='Inglarerra'
							icon={
								<span style={{ fontSize: '4rem' }}>{countryToFlag('GB')}</span>
							}
							to='/inglaterra'
							loading={!ultimaActualizacionPedidos && fetchingPedidos}>
							<div style={{ textAlign: 'center' }}>
								{getPedidosCompletosInglaterra(pedidos).length} pedidos
							</div>
						</SeccionCard>
					</Grid>

					<Grid item hidden={loadingOrder}>
						<SeccionCard
							active={getPedidosCompletosEstadosUnidos(pedidos).length > 0}
							titulo='Estados Unidos'
							icon={
								<span style={{ fontSize: '4rem' }}>{countryToFlag('US')}</span>
							}
							to='/estadosunidos'
							loading={!ultimaActualizacionPedidos && fetchingPedidos}>
							<div style={{ textAlign: 'center' }}>
								{getPedidosCompletosEstadosUnidos(pedidos).length} pedidos
							</div>
						</SeccionCard>
					</Grid>

					<Grid item hidden={loadingOrder}>
						<SeccionCard
							active={getPedidosGuanxe(pedidos).length > 0}
							titulo='Guanxe'
							imagen='gxe-logo-mini.png'
							to='/guanxe'
							loading={!ultimaActualizacionPedidos && fetchingPedidos}>
							<div style={{ textAlign: 'center' }}>
								{getPedidosGuanxe(pedidos).length} pedidos
							</div>
						</SeccionCard>
					</Grid>

					<Grid item hidden={loadingOrder}>
						<SeccionCard
							active={getPedidosMiravia(pedidos).length > 0}
							titulo='Miravia'
							imagen='miravia_icon.png'
							to='/miravia'
							loading={!ultimaActualizacionPedidos && fetchingPedidos}>
							<div style={{ textAlign: 'center' }}>
								{getPedidosMiravia(pedidos).length} pedidos
							</div>
						</SeccionCard>
					</Grid>
				</Grid>
			</div>
		</Contenido>
	);
}
