import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import PrintButton from './PrintButton';

export default function ListPdfDownloadButton({
	titulo = '',
	subtitulo = '',
	cabeceras = [],
	datos = [],
}) {
	const [url, setUrl] = useState('');

	const downloadListPdf = useCallback(async () => {
		// TODO Cambiar a AWS cuando se resuelva el problema de los pdf
		const blobData = await axios
			.post(
				// '/api/generate-pdf',
				'https://pedidos.sabinastore.com/api/generate-pdf',
				{
					title: titulo,
					subtitle: subtitulo,
					headers: cabeceras,
					data: datos,
				},
				{
					responseType: 'blob',
					withCredentials: true,
				}
			)
			.then((response) => response.data)
			.catch((error) => {
				console.error('Error generando pdf', error);
				return null;
			});

		if (blobData) {
			const url = window.URL.createObjectURL(
				new Blob([blobData], { type: 'application/pdf' })
			);
			setUrl(url);
		}
	}, [titulo, subtitulo, cabeceras, datos]);

	useEffect(() => {
		if (datos.length > 0) {
			downloadListPdf().then(() => null);
		}
	}, [datos, downloadListPdf]);

	return (
		<PrintButton
			url={url}
			disabled={url === ''}
			variant={'outlined'}
			color={'inherit'}
		/>
	);
}
