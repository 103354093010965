import React from 'react';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { Alert, AlertTitle } from '@material-ui/lab';
import { red } from '@material-ui/core/colors';
import TablaGrabadosLaser from '../tablas/TablaGrabadosLaser';
import { Badge, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import PrintButton from '../botones/PrintButton';

function Dialogo({ title, open, onClose, children, actions }) {
	return (
		<Dialog fullWidth scroll='paper' open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers style={{ minHeight: '8rem' }}>
				{children}
			</DialogContent>
			{actions && <DialogActions>{actions}</DialogActions>}
		</Dialog>
	);
}

export default function IconoLaserProducto({ order, detail }) {
	const { NOMBRECOMERCIAL } = order.SHIPMENT;
	const { ORDER_ID, PRODUCT_ID, HAS_LASER_ENGRAVINGS, LASER_ENGRAVINGS } =
		detail;

	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const body = (
		<Box>
			<strong>ID Pedido:</strong> {ORDER_ID}
			<br />
			<strong>Cliente:</strong> {NOMBRECOMERCIAL}
			<br />
			<br />
			{HAS_LASER_ENGRAVINGS ? (
				<Box
					style={{
						height: '200px',
						width: '100%',
					}}>
					<TablaGrabadosLaser pedido={order} CODARTICULO={PRODUCT_ID} />
				</Box>
			) : (
				<Alert severity='error' style={{ marginTop: '1rem' }}>
					<AlertTitle>Error obteniendo los datos</AlertTitle>
					Contacte con el administrador.
				</Alert>
			)}
		</Box>
	);

	return (
		<>
			{HAS_LASER_ENGRAVINGS ? (
				<Badge
					color='secondary'
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					badgeContent={LASER_ENGRAVINGS.length}>
					<FlashOnIcon onClick={handleOpen} />
				</Badge>
			) : (
				<ErrorOutlineOutlinedIcon
					style={{ color: red[500] }}
					onClick={handleOpen}
				/>
			)}

			<Dialogo
				title={'Grabado Láser'}
				open={open}
				onClose={handleClose}
				actions={
					<PrintButton
						// url={`${axios.defaults.baseURL}/api/laser/${ORDER_ID}.pdf`}
						// TODO Cambiar a AWS cuando se resuelva el problema de los pdf
						url={`https://pedidos.sabinastore.com/api/laser/${ORDER_ID}.pdf`}
						disabled={!HAS_LASER_ENGRAVINGS}
					/>
				}>
				{body}
			</Dialogo>
		</>
	);
}
