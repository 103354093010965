import moment from 'moment';
import {
	CODALMACEN_TRASPASOS_OSABINA,
	EMAILS_BLOQUEADOS,
	PRECIO_BLOQUEO_PEDIDOS_EEUU,
} from '../constants';
import domtoimage from 'dom-to-image';
import axios from 'axios';

export const esPedidoCompleto = (order) => order.COMPLETE;

export const esPedidoEnPreparacion = (order) => Boolean(order.PICKING);

export const esPedidoBloqueado = (order) =>
	order.LOCKED || esPedidoEmailBloqueado(order);

export const esPedidoEmailBloqueado = (order) =>
	EMAILS_BLOQUEADOS.some(
		(emailBloqueado) => order.SHIPMENT.EMAIL.indexOf(emailBloqueado) !== -1
	);

export const esPedidoParalizado = (order) => order.PARALYZED;

export const esPedidoMarketplace = (order) => order.MARKETPLACE !== null;

export const esPedidoGuanxe = (order) => order.MARKETPLACE === 'GUANXE';

export const esPedidoAliExpress = (order) => false;

export const esPedidoMiravia = (order) => order.MARKETPLACE === 'MIRAVIA';

export const esPedidoAmazon = (order) => false;

export const esPedidoPaisEspecial = (order) =>
	esPedidoInglaterra(order) || esPedidoEstadosUnidos(order);

export const tieneCodigoPostalUnionEuropea = (order) =>
	order.SHIPMENT.CODPOSTAL.indexOf('BT') === 0;

export const esPedidoInglaterra = (order) =>
	order.SHIPMENT.PAIS === 'INGLATERRA' && !tieneCodigoPostalUnionEuropea(order);

export const esPedidoEstadosUnidos = (order) =>
	order.SHIPMENT.PAIS === 'ESTADOS UNIDOS';

export const esPedidoATiendaCancelado = (request) =>
	request.CANCELED_UNIXDATE !== null;

export const esPedidoATiendaRecibido = (request) =>
	!esPedidoATiendaCancelado(request) && false;

export const esPedidoATiendaEnviado = (request) =>
	!esPedidoATiendaCancelado(request) &&
	!esPedidoATiendaRecibido(request) &&
	request.SENDED_UNIXDATE !== null;

export const esPedidoATiendaExtraviado = (request) =>
	!esPedidoATiendaCancelado(request) &&
	!esPedidoATiendaEnviado(request) &&
	!esPedidoATiendaRecibido(request) &&
	false;

export const esPedidoATiendaRetrasado = (request) =>
	!esPedidoATiendaCancelado(request) &&
	!esPedidoATiendaRecibido(request) &&
	!esPedidoATiendaExtraviado(request) &&
	getHorasDesde(request.UNIXDATE) > 24;

export const getUnidadesPendientes = (detail) => detail.INCOMPLETE_AMOUNT;

export const tieneProductoSinStock = (order) => order.HAS_SOLD_OUT;

export const esProductoCrossSelling = (detail) => detail.CROSS_SELLING || false;

export const tieneProductoCrossSelling = (order) =>
	order.DETAILS.some((d) => esProductoCrossSelling(d));

export const getPedidosBloqueados = (pedidos) =>
	pedidos.filter((p) => esPedidoBloqueado(p));

export const esRequestTraspaso = (request) =>
	request.STORE_TO_ID === CODALMACEN_TRASPASOS_OSABINA;

export const esRequestSolicitado = (request) => request.UNIXDATE !== null;

export const esRequestActivo = (request) =>
	esRequestSolicitado(request) && request.CANCELED_UNIXDATE === null;

export const getPedidosATiendaActivos = (order) =>
	order.REQUESTS.filter((r) => esRequestActivo(r) && !esRequestTraspaso(r));

export const getTraspasosActivos = (order) =>
	order.REQUESTS.filter((r) => esRequestActivo(r) && esRequestTraspaso(r));

export const getPedidosATiendaFuturos = (order) =>
	order.REQUESTS.filter(
		(r) => !esRequestSolicitado(r) && !esRequestTraspaso(r)
	);

export const getTraspasosFuturos = (order) =>
	order.REQUESTS.filter((r) => !esRequestSolicitado(r) && esRequestTraspaso(r));

export const getHorasDesde = (unix) => {
	const mUnix = moment.unix(unix);
	const mNow = moment();
	return parseInt(mNow.diff(mUnix, 'hours'));
};

export const getPedidosPreparar = (pedidos) =>
	pedidos.filter(
		(p) =>
			(esPedidoCompleto(p) || esPedidoEnPreparacion(p)) &&
			!esPedidoBloqueado(p) &&
			!esPedidoParalizado(p)
	);

export const getPedidosCompletos = (pedidos) =>
	pedidos.filter(
		(p) =>
			(esPedidoCompleto(p) || esPedidoEnPreparacion(p)) &&
			!esPedidoBloqueado(p) &&
			!esPedidoParalizado(p) &&
			!esPedidoPaisEspecial(p) &&
			(!esPedidoMarketplace(p) || esPedidoAliExpress(p))
	);

export const getPedidosCompletosInglaterra = (pedidos) =>
	pedidos.filter(
		(p) =>
			(esPedidoCompleto(p) || esPedidoEnPreparacion(p)) &&
			!esPedidoBloqueado(p) &&
			!esPedidoParalizado(p) &&
			esPedidoInglaterra(p)
	);

export const esPedidoBloqueadoPrecioEstadosUnidos = (pedido) => {
	return (
		esPedidoEstadosUnidos(pedido) && pedido.TOTAL >= PRECIO_BLOQUEO_PEDIDOS_EEUU
	);
};

export const getPedidosCompletosEstadosUnidos = (pedidos) =>
	pedidos.filter(
		(p) =>
			(esPedidoCompleto(p) || esPedidoEnPreparacion(p)) &&
			!esPedidoBloqueado(p) &&
			!esPedidoParalizado(p) &&
			esPedidoEstadosUnidos(p) &&
			!esPedidoBloqueadoPrecioEstadosUnidos(p)
	);

export const getPedidosCompletosEstadosUnidosBloqueados = (pedidos) =>
	pedidos.filter(
		(p) =>
			(esPedidoCompleto(p) || esPedidoEnPreparacion(p)) &&
			!esPedidoBloqueado(p) &&
			!esPedidoParalizado(p) &&
			esPedidoBloqueadoPrecioEstadosUnidos(p)
	);

export const getPedidosGuanxe = (pedidos) =>
	pedidos.filter(
		(p) =>
			(esPedidoCompleto(p) || esPedidoEnPreparacion(p)) &&
			!esPedidoBloqueado(p) &&
			!esPedidoParalizado(p) &&
			!esPedidoPaisEspecial(p) &&
			esPedidoGuanxe(p)
	);

export const getPedidosAmazon = (pedidos) =>
	pedidos.filter(
		(p) =>
			(esPedidoCompleto(p) || esPedidoEnPreparacion(p)) &&
			!esPedidoBloqueado(p) &&
			!esPedidoParalizado(p) &&
			!esPedidoPaisEspecial(p) &&
			esPedidoAmazon(p)
	);

export const getPedidosAliExpress = (pedidos) =>
	pedidos.filter(
		(p) =>
			(esPedidoCompleto(p) || esPedidoEnPreparacion(p)) &&
			!esPedidoBloqueado(p) &&
			!esPedidoParalizado(p) &&
			!esPedidoPaisEspecial(p) &&
			esPedidoAliExpress(p)
	);

export const getPedidosMiravia = (pedidos) =>
	pedidos.filter(
		(p) =>
			(esPedidoCompleto(p) || esPedidoEnPreparacion(p)) &&
			!esPedidoBloqueado(p) &&
			!esPedidoParalizado(p) &&
			!esPedidoPaisEspecial(p) &&
			esPedidoMiravia(p)
	);

export const getPedidosIncompletos = (pedidos) =>
	pedidos.filter(
		(p) =>
			!esPedidoCompleto(p) &&
			!esPedidoEnPreparacion(p) &&
			!esPedidoBloqueado(p) &&
			!esPedidoParalizado(p)
	);

export const getCarrier = (order) => {
	const carrierOrder = order.TRANSPORTE.trim().toUpperCase();
	if (esPedidoGuanxe(order)) {
		return 'maresa';
	} else if (carrierOrder.indexOf('SABINA') === 0) {
		return 'pickup-point';
	} else {
		switch (carrierOrder) {
			case 'SEUR':
				return 'seur';
			case 'DHL':
				return 'dhl';
			case 'PICKUP POINT':
				return 'pickup-point';
			case 'EUROPA':
			case 'PENÍNSULA/BALEA':
				return 'seur-europa';
			case 'CANARIAS':
				return 'seur-canarias';
			default:
				break;
		}
	}
	return '';
};

export const getPedidosCarrier = (pedidos, carrier) => {
	const carriers = Array.isArray(carrier) ? carrier : [carrier];
	return pedidos
		.filter(
			(p) =>
				(esPedidoCompleto(p) || esPedidoEnPreparacion(p)) &&
				!esPedidoBloqueado(p) &&
				!esPedidoParalizado(p) &&
				!esPedidoBloqueadoPrecioEstadosUnidos(p)
		)
		.filter((order) => carriers.includes(getCarrier(order)));
};

export const getProductosCrosselling = (pedidos) => {
	const pedidosIncompletos = getPedidosIncompletos(pedidos);
	const productosCrossSelling = {};
	pedidosIncompletos.forEach((pedido) => {
		pedido.DETAILS.forEach((producto) => {
			if (esProductoCrossSelling(producto)) {
				const { PRODUCT_ID, DESCRIPTION, INCOMPLETE_AMOUNT } = producto;
				productosCrossSelling[PRODUCT_ID] = {
					id: PRODUCT_ID,
					PRODUCT_ID,
					name: DESCRIPTION,
					amount:
						INCOMPLETE_AMOUNT +
						(productosCrossSelling[PRODUCT_ID]?.amount || 0),
				};
			}
		});
	});
	return productosCrossSelling;
};

export const getDataUrlImagenTarjetaRegalo = async ({ texto }) => {
	const html = `
    <div id="tarjetaRegaloImprimir" style=" width: 105mm; height: 148mm; ">
      <div id="tarjetaRegaloImprimir" style=" width: 105mm; height: 148mm; ">
        <table style="height: 100%; position: relative; width: 100%; margin: 0; " cellPadding="0" cellSpacing="0">
          <tr>
            <td style=" width: 210mm; font-family: 'Open Sans', sans-serif; font-weight: 400; text-align: center;">
              <div style="font-size: 1.2rem; -moz-transform: rotate(-90.0deg); -o-transform: rotate(-90.0deg); -webkit-transform: rotate(-90.0deg); transform: rotate(-90.0deg); padding-bottom: 5rem;">
                ${texto}
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  `;

	const element = document.createElement('html');
	element.id = 'bloqueTarjetaRegaloImprimir';
	element.innerHTML = html;
	document.body.appendChild(element);

	const options = {
		width: 397,
		height: 559,
	};

	const value = await domtoimage.toJpeg(element, options);
	element.remove();

	return value;
};

export const getPdfLaserUrl = (SUPEDIDO) =>
	// `${axios.defaults.baseURL}/api/laser/${SUPEDIDO}.pdf`;
	// TODO Cambiar a AWS cuando se resuelva el problema de los pdf
	`https://pedidos.sabinastore.com/api/laser/${SUPEDIDO}.pdf`;

export const getPdfInvoiceUrl = (SUPEDIDO, equipo) => {
	if (equipo === 'COMPLEMENTOS') {
		// return `${axios.defaults.baseURL}/api/osabina/facturas/${SUPEDIDO}.pdf`;
		// TODO Cambiar a AWS cuando se resuelva el problema de los pdf
		return `https://pedidos.sabinastore.com/api/osabina/facturas/${SUPEDIDO}.pdf`;
	}
	// return `${axios.defaults.baseURL}/api/facturas/${SUPEDIDO}.pdf`;
	// TODO Cambiar a AWS cuando se resuelva el problema de los pdf
	return `https://pedidos.sabinastore.com/api/facturas/${SUPEDIDO}.pdf`;
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export const countryToFlag = (isoCode) => {
	return typeof String.fromCodePoint !== 'undefined'
		? isoCode
				.toUpperCase()
				.replace(/./g, (char) =>
					String.fromCodePoint(char.charCodeAt(0) + 127397)
				)
		: isoCode;
};

export const agruparPedidos = (pedidos) => {
	const index = [];

	pedidos.forEach((pedido) => {
		// buscar pedidos similares
		const position = index.find((position) => {
			if (Object.keys(position.products).length === pedido.DETAILS.length) {
				return pedido.DETAILS.every((detail) =>
					Boolean(position.products[detail.PRODUCT_ID])
				);
			}
			return false;
		});

		if (position) {
			position.orders.push(pedido);
		} else {
			const newPosition = {
				products: {},
				orders: [pedido],
			};
			pedido.DETAILS.forEach((detail) => {
				newPosition.products[detail.PRODUCT_ID] = detail.DESCRIPTION;
			});
			index.push(newPosition);
		}
	});

	return index.map((position, pos) => ({ id: pos, ...position }));
};

export const openToPrint = async ({
	url = '',
	onLoad = ({ success = true }) => {},
}) => {
	// const w = window.open(url, '_blank');
	// w.document.close();

	document.getElementById('iframe-print')?.remove();

	function onloadIframe() {
		this.contentWindow.print();
		onLoad({ success: true });
	}

	let iframeSrc = '';
	const blobFile = await axios
		.get(url, {
			responseType: 'blob',
			baseURL: '',
		})
		.then((response) => response.data)
		.catch(() => null);
	iframeSrc = URL.createObjectURL(blobFile);

	if (iframeSrc) {
		const hideFrame = document.createElement('iframe');
		hideFrame.setAttribute('id', 'iframe-print');
		hideFrame.onload = onloadIframe;
		hideFrame.style.display = 'none'; // hide iframe
		hideFrame.src = iframeSrc;
		document.body.appendChild(hideFrame);
	} else {
		onLoad({ success: false });
	}
};
