import React, { useCallback, useEffect, useRef, useState } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import BotonImagenesPedidoTienda from '../../botones/BotonImagenesPedidoTienda';
import { useParams } from 'react-router-dom';
import {
	fetchEnviarPedidoTiendaApi,
	fetchPedidoTiendaApi,
} from '../../../api/common.services';
import {
	AppBar,
	Box,
	Button,
	InputBase,
	Toolbar,
	Typography,
} from '@material-ui/core';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import FinalizeDialog from './FinalizeDialog';
import ErrorCodeDialog from './ErrorCodeDialog';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import Backdrop from '@material-ui/core/Backdrop';
import socketIOClient from 'socket.io-client';
import axios from 'axios';
import DownloadButton from '../../botones/DownloadButton';
import PrintButton from '../../botones/PrintButton';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	title: {
		flexGrow: 1,
		display: 'block',
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '20ch',
		'&:focus': {
			width: '25ch',
		},
	},
	botonFinalizar: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '12ch',
			'&:focus': {
				width: '20ch',
			},
		},
	},
	filaArticuloCompleto: {
		backgroundColor: green[100],
	},
}));

const Almacen = () => {
	const inputSearchRef = useRef(null);

	const classes = useStyles();

	const [lastUpdateData, setLastUpdateData] = useState(new Date());

	let { CODALMACEN, CODIGOFECHA } = useParams();
	const [openFatalError, setOpenFatalError] = useState(false);
	const [sended, setSended] = useState(false);
	const [loadingSended, setLoadingSended] = useState(false);
	const [openAddedSnackBar, setOpenAddedSnackBar] = useState(false);
	const [openCompleteSnackBar, setOpenCompleteSnackBar] = useState(false);
	const [openFinalize, setOpenFinalize] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [isErrorCode, setIsErrorCode] = useState(false);
	const [maxUnitsCode, setMaxUnitsCode] = useState(null);
	const [loading, setLoading] = useState(true);
	const [pedidos, setPedidos] = useState([]);
	const [valueTextFieldDebug, setValueTextFieldDebug] = useState('');
	const [isValidTextFieldDebug, setIsValidTextFieldDebug] = useState(null);
	const [insertedCodes, setInsertedCodes] = useState({});

	const [shouldUpdate, setShouldUpdate] = useState(Date.now());

	const socketIoConnection = useCallback(() => {
		const socket = socketIOClient(axios.defaults.baseURL, {
			/* query: {
        userId: 986
      } */
		});

		// socket.on('hello', () => {
		// 	console.log('socket hello');
		// });

		socket.on('refreshStoreOrders', (payload) => {
			if (payload.store_id.toString() === CODALMACEN.toString()) {
				setShouldUpdate(Date.now());
			}
		});

		/* socket.on('disconnect', () => {
      console.log('disconnected');
    });

    socket.io.on('reconnect_error', (err) => {
      console.log('socket reconnect error: ',err);
    });
    socket.io.on('error', (err) => {
      console.log('socket err: ',err);
    }); */

		return () => {
			socket.disconnect();
			socket.close();
		};
	}, [CODALMACEN]);

	useEffect(() => socketIoConnection(), [socketIoConnection]);

	const send = () => {
		setSended(true);
		setLoadingSended(true);
		setOpenFinalize(false);

		fetchEnviarPedidoTiendaApi({
			CODALMACEN,
			CODIGOFECHA,
			insertedCodes,
		}).then((res) => {
			const { success } = res;
			if (success) {
				// TODO Something
			} else {
				setOpenFatalError(true);
			}
			setLastUpdateData(new Date());
			setLoadingSended(false);
		});
	};

	const checkIsComplete = () => {
		if (pedidos.length) {
			const isAllComplete = pedidos.every(
				(p) => insertedCodes[p.ID] && insertedCodes[p.ID] === p.CANTIDAD
			);
			setIsComplete(isAllComplete);
			if (isAllComplete) {
				setOpenFinalize(true);
			}
		}
	};

	const checkInsertedCode = (valueCode) => {
		if (
			openFatalError ||
			sended ||
			isComplete ||
			isErrorCode ||
			openFinalize ||
			maxUnitsCode !== null ||
			valueCode === ''
		) {
			if (valueCode !== '') {
				setIsValidTextFieldDebug(null);
				setValueTextFieldDebug('');
			}
			return;
		}

		setValueTextFieldDebug(valueCode);

		const hasPedidosWithBarcode = pedidos.some((p) =>
			p.barcodes.some((bc) => bc === valueCode)
		);
		const pedido = pedidos.find((p) => {
			return (
				p.barcodes.some((bc) => bc === valueCode) &&
				p.CANTIDAD > (insertedCodes[p.ID] || 0)
			);
		});

		if (hasPedidosWithBarcode && !pedido) {
			setMaxUnitsCode(valueCode);
			return;
		}

		if (pedido) {
			setIsValidTextFieldDebug(true);
			const insertedUnits = insertedCodes[pedido.ID] || 0;
			insertedCodes[pedido.ID] = insertedUnits + 1;
			setInsertedCodes({
				...insertedCodes,
			});
			if (pedido.CANTIDAD === insertedCodes[pedido.ID]) {
				setOpenCompleteSnackBar(true);
			} else {
				setOpenAddedSnackBar(true);
			}
		} else {
			setIsValidTextFieldDebug(false);
			setIsErrorCode(true);
		}
	};

	useEffect(() => {
		fetchPedidoTiendaApi({
			CODALMACEN,
			CODIGOFECHA,
		}).then((res) => {
			const { success, data } = res;
			if (success) {
				setPedidos(data);
			} else {
				setOpenFatalError(true);
			}
			setLoading(false);
		});
	}, [CODALMACEN, CODIGOFECHA, lastUpdateData, shouldUpdate]);

	//automatic update every 3 minutes
	/* useEffect(() => {
    setInterval(() => {
      setShouldUpdate(Date.now());
    }, 180*1000);
  }, []); */

	useEffect(() => {
		pedidos.forEach((pedido) => {
			if (pedido.ENVIADO !== null) {
				insertedCodes[pedido.ID] = pedido.CANTIDAD;
				setInsertedCodes({
					...insertedCodes,
				});
			}
		});
		if (pedidos.some((p) => p.ENVIADO !== null)) {
			setSended(true);
		}
	}, [pedidos, insertedCodes]);

	useEffect(checkIsComplete, [insertedCodes, pedidos]);

	const submitSearch = (e) => {
		e.preventDefault();
		const barcode = e.target.searchValue.value;
		checkInsertedCode(barcode);
		inputSearchRef.current.value = '';
		inputSearchRef.current.focus();
	};

	useEffect(() => {
		setInterval(() => {
			if (inputSearchRef.current !== document.activeElement) {
				inputSearchRef.current.focus();
			}
		}, 5000);
	}, []);

	const data = pedidos.map((d, i) => ({ id: i, ...d }));
	return (
		<>
			<Box className={classes.root}>
				<AppBar position='static' style={{ height: '4rem' }}>
					<Toolbar>
						<DownloadButton
							// url={`${axios.defaults.baseURL}/api/pedidosTienda/${CODALMACEN}.pdf`}
							// TODO Cambiar a AWS cuando se resuelva el problema de los pdf
							url={`https://pedidos.sabinastore.com/api/pedidosTienda/${CODALMACEN}.pdf`}
							fileName={'pedidoTienda'}
							variant={'outlined'}
							color={'inherit'}
							style={{ marginRight: '1rem' }}
						/>
						<PrintButton
							// url={`${axios.defaults.baseURL}/api/pedidosTienda/${CODALMACEN}.pdf`}
							// TODO Cambiar a AWS cuando se resuelva el problema de los pdf
							url={`https://pedidos.sabinastore.com/api/pedidosTienda/${CODALMACEN}.pdf`}
							variant={'outlined'}
							color={'inherit'}
							style={{ marginRight: '1rem' }}
						/>
						<Typography className={classes.title} variant='h6' noWrap>
							Pedido para la web
						</Typography>
						{isValidTextFieldDebug !== null && (
							<InputBase
								startAdornment={
									isValidTextFieldDebug ? (
										<CheckCircleOutlineIcon style={{ marginRight: '0.5rem' }} />
									) : (
										<HighlightOffIcon style={{ marginRight: '0.5rem' }} />
									)
								}
								style={{
									display: 'none',
									color: isValidTextFieldDebug ? '#4ed255' : '#f44336',
								}}
								value={valueTextFieldDebug}
								disabled
							/>
						)}
						<Box className={classes.search}>
							<form onSubmit={submitSearch}>
								<InputBase
									classes={{
										root: classes.inputRoot,
										input: classes.inputInput,
									}}
									name={'searchValue'}
									autoFocus
									inputProps={{
										ref: inputSearchRef,
									}}
								/>
							</form>
						</Box>
						{!sended && (
							<Button
								color='inherit'
								style={{ marginLeft: '1rem' }}
								variant={'outlined'}
								onClick={() => setOpenFinalize(true)}
								disabled={sended}>
								FINALIZAR
							</Button>
						)}
						{loadingSended && (
							<Typography style={{ marginLeft: '1rem' }} noWrap>
								Enviando datos del pedido...
							</Typography>
						)}
					</Toolbar>
				</AppBar>
				{/* {sended &&
        <AppBar position="static" style={{ height: '4rem', backgroundColor: 'green' }}>
          <Toolbar>
            <LocalShippingTwoToneIcon style={{ marginRight: '1rem' }}/>
            <Typography className={classes.title} variant="h6" noWrap>
              Este pedido ya ha sido marcado como enviado
            </Typography>
          </Toolbar>
        </AppBar>
      } */}
			</Box>

			<Box
				style={{
					height: sended ? 'calc(100% - 8rem)' : 'calc(100% - 4rem)',
					width: '100%',
				}}>
				<DataGrid
					rows={data}
					disableColumnMenu
					disableSelectionOnClick
					scrollbarSize={17}
					loading={loading}
					components={{
						NoRowsOverlay: () => (
							<div className='MuiDataGrid-overlay'>
								No hay artículos para preparar
							</div>
						),
					}}
					getRowClassName={({ row: pedido }) => {
						const insertedAmount = insertedCodes[pedido.ID] || 0;
						if (insertedAmount === pedido.CANTIDAD) {
							return classes.filaArticuloCompleto;
						}
						return '';
					}}
					columns={[
						{
							sortable: false,
							field: 'check',
							headerName: ' ',
							width: 75,
							renderCell: ({ row: pedido }) => {
								const insertedAmount = insertedCodes[pedido.ID] || 0;
								if (insertedAmount === 0) {
									return (
										<CheckBoxOutlineBlankOutlinedIcon
											style={{ marginLeft: '1rem' }}
										/>
									);
								} else if (insertedAmount === pedido.CANTIDAD) {
									return (
										<CheckBoxIcon
											style={{
												color: green[700],
												marginLeft: '1rem',
											}}
										/>
									);
								}
								return (
									<IndeterminateCheckBoxOutlinedIcon
										style={{ marginLeft: '1rem' }}
									/>
								);
							},
						},
						{
							sortable: false,
							field: 'CODARTICULO',
							headerName: 'Codigo interno',
							width: 150,
							renderCell: ({ value }) => {
								return (
									<div
										style={{
											textAlign: 'center',
											width: '100%',
										}}>
										{value}
									</div>
								);
							},
						},
						{
							sortable: false,
							field: 'CANTIDAD',
							headerName: 'Cantidad',
							width: 90,
							renderCell: ({ value }) => {
								return (
									<div
										style={{
											textAlign: 'center',
											width: '100%',
										}}>
										{value}
									</div>
								);
							},
						},
						{
							sortable: false,
							field: 'unidadesEnviadas',
							headerName: 'Enviadas',
							width: 100,
							renderCell: ({ row: pedido }) => {
								const insertedAmount = insertedCodes[pedido.ID] || 0;
								return (
									<div
										style={{
											textAlign: 'center',
											width: '100%',
										}}>
										{insertedAmount}
									</div>
								);
							},
						},
						{
							sortable: false,
							field: 'images',
							headerName: 'Detalles',
							width: 150,
							renderCell: ({ value, row: pedidoATienda }) => {
								return (
									<BotonImagenesPedidoTienda
										pedidoATienda={pedidoATienda}
										inputSearchRef={inputSearchRef}
									/>
								);
							},
						},
						{
							sortable: false,
							field: 'almacenSolicita',
							headerName: 'Almacén',
							width: 150,
						},
						{
							sortable: false,
							field: 'DESCRIPCION',
							headerName: 'Producto',
							width: 200,
							flex: 1,
						},
					]}
				/>
			</Box>

			<FinalizeDialog
				open={pedidos.length > 0 && openFinalize && !sended}
				handleClose={() => setOpenFinalize(false)}
				handleSend={send}
				resume={{
					isComplete,
					pedidos,
					insertedCodes,
				}}
			/>
			<ErrorCodeDialog
				open={isErrorCode}
				handleClose={() => setIsErrorCode(false)}
				type={'wrongCode'}
			/>
			<ErrorCodeDialog
				open={maxUnitsCode !== null}
				handleClose={() => setMaxUnitsCode(null)}
				type={'wrongAmount'}
			/>
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={openCompleteSnackBar}
				autoHideDuration={500}
				onClose={() => setOpenCompleteSnackBar(false)}>
				<Alert severity={'success'} variant={'filled'}>
					Producto completado!
				</Alert>
			</Snackbar>

			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={openAddedSnackBar}
				autoHideDuration={500}
				onClose={() => setOpenAddedSnackBar(false)}
				message={'Producto añadido'}
			/>

			<Backdrop open={openFatalError} style={{ zIndex: 1000 }}>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={openFatalError}>
					<Alert severity={'error'} variant={'filled'}>
						Ha ocurrido un error, contacte con el administrador
					</Alert>
				</Snackbar>
			</Backdrop>
		</>
	);
};

export default Almacen;
