import React, { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import BeenhereTwoToneIcon from '@material-ui/icons/BeenhereTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';
import PanToolTwoToneIcon from '@material-ui/icons/PanToolTwoTone';
import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LocalShippingTwoToneIcon from '@material-ui/icons/LocalShippingTwoTone';
import RepeatIcon from '@material-ui/icons/Repeat';
import SettingsIcon from '@material-ui/icons/Settings';
import { blue, blueGrey, green, red } from '@material-ui/core/colors';

import {
	countryToFlag,
	getPedidosCarrier,
	getPedidosCompletos,
	getPedidosCompletosEstadosUnidos,
	getPedidosCompletosEstadosUnidosBloqueados,
	getPedidosCompletosInglaterra,
	getPedidosGuanxe,
	getPedidosIncompletos,
	getPedidosMiravia,
	getProductosCrosselling,
	tieneProductoCrossSelling,
} from '../../utils';
import ListItemSeccion from './ListItemSeccion';
import { Switch } from '@material-ui/core';
import AuthService from '../../services/auth.service';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
}));

const Sidebar = () => {
	const [carriersMode, setCarriersMode] = useState(false);

	const [loadingActiveOrders, setLoadingActiveOrders] = useState(false);
	const [updatingActiveOrders, setUpdatingActiveOrders] = useState(false);

	const [loadingInactiveOrders, setLoadingInactiveOrders] = useState(false);
	const [updatingInactiveOrders, setUpdatingInactiveOrders] = useState(false);

	const { user, esAdmin } = useSelector((content) => content.User);

	const {
		fetching: fetchingPedidos,
		ultimaActualizacion: ultimaActualizacionPedidos,
		pedidos,
	} = useSelector((content) => content.Pedidos);
	const {
		fetching: fetchingParalizados,
		ultimaActualizacion: ultimaActualizacionParalizados,
		paralizados,
	} = useSelector((content) => content.Paralizados);
	const { fetching: fetchingFacturas } = useSelector(
		(content) => content.Facturas
	);

	const logout = async () => {
		AuthService.logout();
		// eslint-disable-next-line no-restricted-globals
		location.reload();
	};

	const classes = useStyles();

	useEffect(() => {
		setLoadingActiveOrders(fetchingPedidos && !ultimaActualizacionPedidos);
		setUpdatingActiveOrders(fetchingPedidos && ultimaActualizacionPedidos);
	}, [fetchingPedidos, ultimaActualizacionPedidos]);

	useEffect(() => {
		setLoadingInactiveOrders(
			fetchingParalizados && !ultimaActualizacionParalizados
		);
		setUpdatingInactiveOrders(
			fetchingParalizados && ultimaActualizacionParalizados
		);
	}, [fetchingParalizados, ultimaActualizacionParalizados]);

	return (
		<Drawer
			className={classes.drawer}
			variant='permanent'
			classes={{
				paper: classes.drawerPaper,
			}}
			anchor='left'>
			<div className={classes.toolbar} style={{ textAlign: 'center' }}>
				<h2>{user.equipo === 'PERFUMERIA' ? 'Sabina' : 'ÖSabina'}</h2>
			</div>

			<Divider />
			<List>
				<li>
					<Switch
						defaultChecked={false}
						color={'default'}
						onChange={(event) => setCarriersMode(event.target.checked)}
					/>{' '}
					Por transportista
				</li>
			</List>
			<Divider />

			<List>
				<ListItemSeccion
					loading={false}
					to='/'
					primary='Dashboard'
					icon={<HomeTwoToneIcon style={{ color: blueGrey[900] }} />}
				/>

				{esAdmin &&
					pedidos &&
					pedidos.some((pedido) => tieneProductoCrossSelling(pedido)) && (
						<ListItemSeccion
							loading={loadingActiveOrders}
							updating={updatingActiveOrders}
							numProductos={
								Object.values(getProductosCrosselling(pedidos)).length
							}
							to='/cross-selling'
							primary='Cross Selling'
							icon={<RepeatIcon style={{ color: blue[700] }} />}
						/>
					)}
			</List>

			<Divider />

			<List hidden={carriersMode}>
				{esAdmin && (
					<ListItemSeccion
						loading={loadingActiveOrders}
						updating={updatingActiveOrders}
						numPedidos={getPedidosIncompletos(pedidos).length}
						to='/incompletos'
						primary='Incompletos'
						icon={<WarningTwoToneIcon style={{ color: red[400] }} />}
					/>
				)}

				<ListItemSeccion
					loading={loadingActiveOrders}
					updating={updatingActiveOrders}
					to='/completos'
					numPedidos={getPedidosCompletos(pedidos).length}
					primary='Preparacion'
					icon={
						<BeenhereTwoToneIcon style={{ color: green[500] }} />
					}></ListItemSeccion>

				<ListItemSeccion
					loading={loadingActiveOrders}
					updating={updatingActiveOrders}
					numPedidos={getPedidosCompletosInglaterra(pedidos).length}
					to='/inglaterra'
					primary='Inglaterra'
					icon={
						<span style={{ fontSize: '1.5rem' }}>{countryToFlag('GB')}</span>
					}
				/>

				<ListItemSeccion
					loading={loadingActiveOrders}
					updating={updatingActiveOrders}
					numPedidos={getPedidosCompletosEstadosUnidos(pedidos).length}
					to='/estadosunidos'
					primary='Estados Unidos'
					icon={
						<span style={{ fontSize: '1.5rem' }}>{countryToFlag('US')}</span>
					}
				/>

				{esAdmin && (
					<ListItemSeccion
						loading={loadingActiveOrders}
						updating={updatingActiveOrders}
						numPedidos={
							getPedidosCompletosEstadosUnidosBloqueados(pedidos).length
						}
						to='/estadosunidosbloqueados'
						primary='EEUU Bloqueados'
						icon={
							<span style={{ fontSize: '1.5rem' }}>{countryToFlag('US')}</span>
						}
					/>
				)}

				<ListItemSeccion
					loading={loadingActiveOrders}
					updating={updatingActiveOrders}
					numPedidos={getPedidosGuanxe(pedidos).length}
					to='/guanxe'
					primary='Guanxe'
					icon={
						<img
							alt='AliExpress'
							src='/gxe-logo-mini.png'
							style={{ height: 30 }}
						/>
					}
				/>

				<ListItemSeccion
					loading={loadingActiveOrders}
					updating={updatingActiveOrders}
					numPedidos={getPedidosMiravia(pedidos).length}
					to='/miravia'
					primary='Miravia'
					icon={
						<img alt='Miravia' src='/miravia_icon.png' style={{ height: 30 }} />
					}
				/>

				{esAdmin && (
					<>
						<ListItemSeccion
							loading={loadingInactiveOrders}
							updating={updatingInactiveOrders}
							numPedidos={paralizados.length}
							to='/paralizados'
							primary='Bloqueados'
							icon={<PanToolTwoToneIcon />}
						/>
						<ListItemSeccion
							loading={fetchingFacturas}
							to='/facturas'
							primary='Facturas'
							icon={<ReceiptTwoToneIcon style={{ color: blue[700] }} />}
						/>
					</>
				)}
			</List>

			<List hidden={!carriersMode}>
				<ListItemSeccion
					loading={loadingActiveOrders}
					updating={updatingActiveOrders}
					numPedidos={
						getPedidosCarrier(pedidos, ['seur', 'seur-europa', 'seur-canarias'])
							.length
					}
					to='/orders/seur'
					primary={
						<img
							alt={'SEUR'}
							src={'/carrier/logoSeur.png'}
							style={{ height: 25 }}
						/>
					}
					icon={<LocalShippingTwoToneIcon style={{ color: blue[700] }} />}
				/>
				<ListItemSeccion
					loading={loadingActiveOrders}
					updating={updatingActiveOrders}
					numPedidos={getPedidosCarrier(pedidos, 'maresa').length}
					to='/orders/maresa'
					primary={
						<img
							alt={'MARESA'}
							src={'/carrier/logoMaresa.png'}
							style={{ height: 25 }}
						/>
					}
					icon={<LocalShippingTwoToneIcon style={{ color: blue[700] }} />}
				/>
				<ListItemSeccion
					loading={loadingActiveOrders}
					updating={updatingActiveOrders}
					numPedidos={getPedidosCarrier(pedidos, 'pickup-point').length}
					to='/orders/pickup-point'
					primary={
						<img
							alt={'Pickup Point'}
							src={'/carrier/logoPickupPoint.png'}
							style={{ height: 25 }}
						/>
					}
					icon={<LocalShippingTwoToneIcon style={{ color: blue[700] }} />}
				/>
				<ListItemSeccion
					loading={loadingActiveOrders}
					updating={updatingActiveOrders}
					numPedidos={getPedidosCarrier(pedidos, 'dhl').length}
					to='/orders/dhl'
					primary={
						<img
							alt={'DHL'}
							src={'/carrier/logoDHL.png'}
							style={{ height: 25 }}
						/>
					}
					icon={<LocalShippingTwoToneIcon style={{ color: blue[700] }} />}
				/>
				<ListItemSeccion
					loading={loadingActiveOrders}
					updating={updatingActiveOrders}
					numPedidos={getPedidosCarrier(pedidos, null).length}
					to='/orders/no-carrier'
					primary={'Sin transportista'}
					icon={<LocalShippingTwoToneIcon style={{ color: blue[700] }} />}
				/>
			</List>

			<Divider />

			<List>
				{esAdmin && (
					<>
						<ListItemSeccion
							to='/settings'
							primary={'Settings'}
							icon={<SettingsIcon />}
						/>
					</>
				)}

				<ListItem button onClick={logout} component='a'>
					<ListItemIcon>
						<PowerSettingsNewIcon />
					</ListItemIcon>
					<ListItemText primary='Cerrar sesión' />
				</ListItem>
			</List>
		</Drawer>
	);
};

export default Sidebar;
